<template>
  <default-page
    :style="
      'background-image:url(' +
      require('@/assets/img/hero03.png') +
      '); background-size:cover;'
    "
  >
    <page-image
      :imgURL="require('@/assets/img/hero02.png')"
      title="Numazu Distillery"
      subTitle="沼津蒸留所"
      fontSize="4rem"
    />
    <section-frame
      title="About Us"
      subTitle="蒸留所について"
      fontSize="3rem"
      color="grey darken-1"
      :paragraphs="about"
      :bBack="true"
    />
    <section-frame
      title="Opening Hours"
      subTitle="営業時間"
      fontSize="3rem"
      color="grey darken-3"
      :paragraphs="opening"
      :bBack="true"
    >
      <!-- <div class="purple ma-10 pa-5" style="font-size: 0.9rem">
        緊急事態宣言の発令に伴い<br />令和3年8月20日~令和3年9月20日の間は<br />時短営業とさせていただきます
      </div> -->
      <div width="60%" max-width="500px" style="backgrond-color: red">
        <ul>
          <li>
            少人数での営業のため恐れ入りますがスタッフが外出中は対応できない場合もございます
          </li>
          <li>
            事前にインスタグラム(<a
              href="https://www.instagram.com/numazu_distillery"
              >@numazu_disitllery<v-icon small class="white--text"
                >mdi-open-in-new</v-icon
              ></a
            >)のDMかTELにてご連絡いただけますと幸いです
          </li>
          <li>
            クラフトジンLAZY
            MASTERは近隣酒販店様でもお取り扱いいただいておりますので併せてご利用ください
          </li>
          <li>季節により営業時間を変更する場合がございます</li>
        </ul>
      </div>
      <div class="mt-6" style="font-weight: bolder; font-size: 0.7rem">
        Open
      </div>
      <div class="text-h6 mt-3">Tue - Fri</div>
      <div>9:00 - 21:00</div>
      <div class="text-caption nowrap">
        <span>※14:00-17:00の間は</span><span>バータイム準備中のため</span
        ><br /><span>スタッフが</span><span>不在の</span><span>場合が</span
        ><span>ございます</span>
      </div>
      <div class="text-h6 mt-3">Sat</div>
      <div>13:00 - 21:00</div>
      <!-- <div class="mt-6" style="font-weight: bolder; font-size: 0.7rem">
        Closed
      </div> -->
      <div class="text-h6 mt-3">Sun</div>
      <div>13:00 - 17:00</div>
            <div class="mt-6" style="font-weight: bolder; font-size: 0.7rem">
        Closed
      </div>
      <div class="text-h6 mt-3">Mon</div>
    </section-frame>
    <section-frame
      title="Access"
      subTitle="アクセス"
      fontSize="3rem"
      color="grey darken-3"
      :bBack="true"
    >
      <v-row color="grey darken-4">
        <v-col cols="12" md="5" class="justify-center text-center">
          <div width="95%" height="auto" class="pa-10">
            <div style="font-size: 1.1rem">沼津蒸留所 / NUMAZU DISTILLERY</div>
            <div class="mt-6" style="font-size: 0.8rem">〒410-0802</div>
            <div style="font-size: 0.8rem">
              静岡県沼津市上土町8 <br />8 Agetsuchi-cho, Numazu, Shizuoka, Jp
            </div>
            <div class="mt-5" style="font-size: 0.8rem">
              ・JR沼津駅 / JR Numazu St. 8min <br />
              ・上土バス停 / Agetsuchi Bus St. 1min
            </div>
            <ul class="mt-6 nowrap" style="font-size: 0.8rem; list-style: none">
              <li>
                <span>・お車でご来店の際は</span><span>恐れ入りますが</span
                ><br /><span>近隣コインパーキングを</span
                ><span>ご利用ください</span>
              </li>
              <li>
                <span>・Sorry, we have no parking.</span
                ><span> Please use near by parking.</span>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" md="7" class="justify-center pa-md-10">
          <div class="mapContainer" style="overflow: hidden">
            <!-- <div class="hideBar"></div> -->
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1BfkS1IyrR1LTHNg5qI7i9rU1N0jw3sCy&ll=35.097463,138.858878&z=17"
              width="100%"
              height="400px"
              frameborder="0"
              style="margin-top: -46px"
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import PageImage from '@/components/PageImage.vue'
export default {
  components: { DefaultPage, SectionFrame, PageImage },
  data() {
    return {
      about: [
        [
          '沼津蒸留所は市内を流れる',
          '狩野川沿いにある',
          '静岡県内唯一の',
          '小さなクラフトジンの',
          '蒸留所です。',
        ],
        [
          'ボタニカルと呼ばれる、',
          '果物や草花、',
          'ハーブ、',
          'スパイスなどを',
          '香りづけに使用した',
          'クラフトジン',
          'LAZY MASTERシリーズの製造を',
          '2020年から行っています。',
        ],
        [
          'おもに県東部や',
          '伊豆半島で取れる',
          'ボタニカルで香り付けをおこない、',
          '手作業で少量ずつ、',
          '丁寧に蒸留された',
          'クラフトジンを',
          'ぜひお試しください。',
        ],
      ],
      opening: [
        [
          '沼津蒸留所では',
          '店頭にて',
          'クラフトジン',
          'LAZY MASTERシリーズを',
          'お求めいただけます',
        ],
      ],
    }
  },
}
</script>
<style scoped>
.mapContainer {
  position: relative;
}
/* .hideBar{
  position: absolute;
  background-color: red;
  height:46px;
  width:100%;
} */
</style>
