<template>
  <default-page>
    <page-image
      :imgURL="require('@/assets/img/hero04.jpg')"
      title="Wholesale"
      subTitle="卸売り"
      fontSize="6rem"
    >
    </page-image>
    <section-frame> このページは準備中です<br>業販につきましてはお問合せください </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import PageImage from '@/components/PageImage.vue'
export default {
  components: { DefaultPage, SectionFrame, PageImage },
}
</script>
