<template>
  <div class="shop_card pa-3 nowrap" style="height: 100%">
    <div class="title">{{ shop.jp_name }}</div>
    <div class="title2">{{ shop.en_name }}</div>
    <div class="label">OPEN</div>
    <div class="description" v-html="shop.open" />
    <div class="label">TEL</div>
    <div class="description">
      <a :href="'tel:' + shop.tel">{{ shop.tel }}</a>
    </div>
    <div class="label">ADDRESS</div>
    <div class="description">{{ shop.postal }}</div>
    <div class="description nowrap">
      <span>{{ pref }}{{ city }}</span
      ><span>{{ shop.address }}</span>
    </div>
    <div class="label">LINK</div>
    <div class="description">
      <v-btn
        v-if="shop.url"
        :href="shop.url"
        rounded
        outlined
        x-small
        class="mx-1"
        >official site</v-btn
      >
      <v-btn
        v-if="shop.instagram"
        :href="'http://www.instagram.com/' + shop.instagram"
        rounded
        outlined
        x-small
        class="mx-1"
        >instagram</v-btn
      >
      <v-btn
        v-if="shop.twitter"
        :href="'http://twitter.com/' + shop.twitter"
        rounded
        outlined
        x-small
        class="mx-1"
        >twitter</v-btn
      >
      <v-btn
        v-if="shop.facebook"
        :href="'http://facebook.com/' + shop.facebook"
        rounded
        outlined
        x-small
        class="mx-1"
        >facebook</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopCard',
  props: {
    shop: {
      type: Object,
      default: () => {},
    },
    pref: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>
.shop_card {
  background-color: rgba(50, 50, 50, 0.9);
}
.title {
  font-weight: lighter;
  font-size: 1.2rem;
  margin-bottom: 0px;
}
.title2 {
  font-weight: lighter;
}
.title:before {
  font-weight: bold;
  /* content: "・";
  left : -1em; */
}
.description {
  font-size: 0.8rem;
  line-height: 1rem;
  color: #bbbbbb;
  /* padding-left:1em; */
}
.label {
  margin-top: 10px;
  font-size: 0.6rem;
  font-weight: bold;
  color: rgb(99, 99, 99);
}
</style>
