<template>
  <default-page>
    <page-image
      :imgURL="require('@/assets/img/topimage/2.jpg')"
      title="The Chamber"
      subTitle="ザ・チェンバー"
      fontSize="5rem"
    >
    </page-image>
    <section-frame title="Welcome" subTitle="ようこそ">
      <p class="nowrap mb-10">
        <span>THE CHAMBERは沼津蒸留所併設の</span
        ><span>テイスティングルーム&amp;カフェバーです</span>
      </p>
      <p class="nowrap mb-10">
        <span>お席はカウンター、</span><span>テラス席を</span
        ><span>ご用意しています。</span><br />
        <span>川沿いの</span><span>狩野川 風のテラスは</span
        ><span>どなたでも</span><span>ご自由に</span><span>お使い</span
        ><span>いただけます。</span>
      </p>
      <v-row>
        <v-col cols="12" md="4" v-for="i in openHours" :key="i.jpName">
          <v-hover v-slot="{ hover }">
            <v-card
              class="mx-auto"
              :class="{ 'on-hover': hover }"
              :href="'#link-' + i.hourName"
            >
              <v-img
                class="white--text align-end"
                height="200px"
                :src="require('@/assets/img/thechamber/' + i.hourName + '.jpg')"
              >
                <v-card-title class="justify-center" align="center">
                  <div>
                    <span class="yellowtail" style="font-size: 4rem">
                      {{ i.enName }}
                    </span>
                    <br />
                    <span style="font-size: 1rem; font-weight: bold">
                      {{ i.jpName }}
                    </span>
                  </div>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </section-frame>
    <section-frame title="Recent Feeds" subTitle="最新情報">
      <the-chamber-feeds />
    </section-frame>
    <section v-for="i in reverseHours" :key="i.jpName">
      <a :name="'link-' + i.hourName" />
      <section-frame
        :title="i.enName"
        :subTitle="i.jpName"
        color="hourBG"
        class="parent grey--text"
      >
        <v-row>
          <v-col cols="12" class="pt-0 pb-3">
            <v-img
              class="white--text align-center"
              height="300px"
              gradient="to top right, rgba(230,115,201,.33), rgba(25,32,80,0.7)"
              :src="require('@/assets/img/thechamber/' + i.hourName + '.jpg')"
            >
              <div
                :id="i.enName"
                class="onImg nowrap text--grey py-4 mx-auto border-y"
                v-html="i.description"
              ></div>
            </v-img>
          </v-col>
          <v-col cols="12" class="pt-1 pb-0">
            <v-sheet
              height="auto"
              width="auto"
              class="mx-3 white--text"
              color="rgba(255,255,255,0.1)"
              style="font-size: 1.4rem; font-weight: 600"
            >
              <div style="padding: 50px">
                <span class="text-caption">営業時間</span><br />
                <span v-html="i.open"></span>
              </div>
            </v-sheet>
          </v-col>
          <v-col class="pb-0">
            <component :is="i.componentName"></component>
          </v-col>
        </v-row>
      </section-frame>
    </section>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import theChamberFeeds from '../components/theChamberFeeds.vue'
import PageImage from '@/components/PageImage.vue'
import TheChamberBar from '@/components/TheChamberBar.vue'
import TheChamberLunch from '@/components/TheChamberLunch.vue'
import TheChamberMorning from '@/components/TheChamberMorning.vue'

export default {
  components: {
    DefaultPage,
    SectionFrame,
    theChamberFeeds,
    PageImage,
    TheChamberBar,
    TheChamberLunch,
    TheChamberMorning,
  },
  data() {
    return {
      openHours: [
        {
          hourName: 'morning',
          enName: 'Morning',
          jpName: 'モーニング',
          open: 'Tue-Fri 7-9, Sat 8-11',
          // open:'現在モーニングの営業を休止しています',
          cutoutSrc: 'coffee.png',
          description:
            '<span>お出かけ前の</span><span>朝時間に</span><br/><span>狩野川沿いで</span><span>コーヒーは</span><br/><span>いかがですか？</span>',
          componentName: 'TheChamberMorning',
        },
        {
          hourName: 'lunch',
          enName: 'Lunch',
          jpName: 'ランチ',
          open: 'Tue-Fri 11-14',
          cutoutSrc: 'dish.png',
          description:
            '<span>ランチタイムは</span><span>曜日ごとに</span><br/><span>いろいろな</span><span>お店のメニューを</span><br/><span>お楽しみいただけます</span>',
          componentName: 'TheChamberLunch',
        },
        {
          hourName: 'bar',
          enName: 'Bar Time',
          jpName: 'バータイム',
          open: '<strong>【営業時間】</strong><br/>Tue-Fri 17-21 / Sat 13-21 / Sun 13-17<br/><span style="font-size:0.8rem;">closed on Monday</span>',
          // open: 'まん延防止措置に伴い現在営業を休止しています',
          cutoutSrc: 'bottle.png',
          description:
            '<span>併設の蒸留所で</span><span>製造する</span><span>沼津の</span><span>クラフトジンを</span><span>使った</span><span>メニューを</span><span>お楽しみください</span>',
          componentName: 'the-chamber-bar',
        },
      ],
    }
  },
  computed: {
    reverseHours() {
      return this.openHours.reverse()
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Yellowtail');
.parent {
  position: relative;
}
.sideImg {
  position: static;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100px;
  z-index: 1;
}
.onImg {
  position: relative;
  z-index: 10;
}
div.onImg.nowrap.text--grey >>> span {
  color: white;
  font-weight: 100;
  font-size: 1.8rem;
  line-height: 1.5rem;
}
.yellowtail {
  font-family: 'Yellowtail', cursive;
}
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.5;
}
.border-y {
  justify-content: center;
  width: 340px;
  border-top: solid 1px #ffffff;
  border-bottom: solid 1px #ffffff;
}
/* .imgDark{
  filter: brightness(10%);
} */
</style>
