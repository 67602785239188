<template>
  <default-page
    :style="
      'background-image:url(' +
      require('@/assets/img/hero03.png') +
      '); background-size:cover;'
    "
  >
    <section-frame title="Shops" subTitle="お取扱店舗">
      <!-- <shop-expansion /> -->
      <shop-list />
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import ShopCard from '@/components/ShopCard.vue'
import TableFilter from '@/components/TableFilter.vue'
// import ShopExpansion from '../components/ShopExpansion.vue'
import ShopList from '@/components/ShopList.vue'
export default {
  components: {
    DefaultPage,
    SectionFrame,
    ShopCard,
    TableFilter,
    // ShopExpansion,
    ShopList
  },
  methods: {
    checkCategory(index) {
      const array = [
        '卸売り',
        '酒販店',
        '飲食店',
        '社内出荷',
        '一般小売',
        '社員割引',
        'サンプル',
        '破損廃棄',
        '会社保管',
        'STORES',
        'さとふる',
        'JTB',
        'Do',
        '自家消費',
      ]
      return array[index]
    },

    isPref(pref) {
      const isFound = this.filteredList.find(
        p => p.pref === pref
      )
      return isFound === undefined ? false : true
    },
    setSelected(str, array) {
      this.$set(this, str, array)
    },
    uniq(array) {
      return [...new Set(array)]
    },
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Yuji+Syuku&display=swap');
.pref {
  font-family: 'Yuji Syuku', serif;
  font-weight: 400;
}
.city {
  font-family: 'Yuji Syuku', serif;
  font-weight: 400;
}
</style>
