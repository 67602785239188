<template>
  <div>
    <v-btn @click="getToken">get instagram access token</v-btn>
  </div>
</template>

<script>
export default {
  method: {
    getToken() {
      const CLIENT_ID = '939928200506755'
      const REDIRECT_URI = 'fe047620b80f74c0a2d1e4ad78c68054'
      const AUTH_URL = `https://api.instagram.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=token`

      window.location.href = AUTH_URL
    },
  },
}
</script>
