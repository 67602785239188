<template>
  <default-page  :style="'background-image:url(' + require('@/assets/img/hero03.png') + '); background-size:cover;'">
    <!-- <page-image

      title="Lazy Master"
      subTitle="レイジーマスター"
      fontSize="5rem"
    >
    </page-image> -->
    <section-frame title="Lazy Master" subTitle="レイジーマスター">
      <lazy-master-about></lazy-master-about>
    </section-frame>
    <section-frame title="Leaflet" subTitle="リーフレット">
      <lazy-master-leaflet></lazy-master-leaflet>
    </section-frame>
    <section-frame title="Lineup" subTitle="商品ラインナップ"
      ><lazy-master-p-r></lazy-master-p-r
    ></section-frame>
        <!-- <section-frame title="Fairly Tale" subTitle="昔ばなし"
      >ミカン仙人の話</section-frame> -->
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import PageImage from '@/components/PageImage.vue'
import LazyMasterPR from '@/components/LazyMasterPR.vue'
import LazyMasterAbout from '@/components/LazyMasterAbout.vue'
import LazyMasterLeaflet from '@/components/LazyMasterLeaflet.vue'
export default {
  components: { DefaultPage, SectionFrame, PageImage, LazyMasterPR, LazyMasterAbout,LazyMasterLeaflet },
}
</script>