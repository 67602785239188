<template>
  <v-container class="mt-5">
    <v-row>
      <v-col cols="12" class="white--text">
        <h1 class="color:white;">This is Dashboard Page</h1>
      </v-col>
      <v-col>
        <instagram-feeds />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import store from '../store'
import InstagramFeeds from '@/components/InstagramFeeds.vue'

export default {
  components: {
    InstagramFeeds,
  },
  computed: {
    // user() {
    //   return store.state.auth.email
    // },
  },
}
</script>
