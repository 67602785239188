<template>
  <v-col cols="12" md="6" order-md="2" :style="'background-color:' + lm.color+' ;'" class="d-flex align-center">
    <v-img :src="imgSrc(index)"></v-img>
  </v-col>
</template>

<script>
import LazyMasterImage from '@/components/LazyMasterImage.vue'
export default {
  components: { LazyMasterImage },
  props: {
    index: {
      type: Number,
      required: true,
    },
    lm: {
      type: Object,
      required: true,
      default: function () {
        return {}
      },
    },
  },
  methods: {
    imgSrc(index) {
      return require(`@/assets/img/lazymaster/${index}.jpg`)
    },
  },
}
</script>
