<template>
  <default-page>
    <page-image
      :imgURL="require('@/assets/img/hero03.jpg')"
      title="Recipe"
      subTitle="レシピ"
      fontSize="6rem"
    ></page-image>
    <section-frame> Comming Soon... </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import PageImage from '@/components/PageImage.vue'
export default {
  components: { DefaultPage, SectionFrame, PageImage },
}
</script>
