<template>
  <v-col cols="12" md="6" order-md="1" style="position: relative">
    <v-img :src="bgStyle(index)" class="imageClass"> </v-img>
    <div class="numberClass">{{ lm.serial }}</div>
    <div class="productTitle">{{ lm.name }}</div>
    <div class="productSub">{{ lm.jpName }}</div>
    <v-row class="textContainer ma-0 pa-md-0 pb-10 text-center">
      <v-col cols="12" class="pb-0">
        <div class="secTitle">Profile</div>
        <div class="description nowrap">
          <span v-for="sentence in lm.description" :key="sentence">{{
            sentence
          }}</span>
        </div>
      </v-col>
      <v-col cols="12" class="list pb-0 nowrap">
        <div class="secTitle">Botanicals</div>
        <div v-for="botanical in lm.botanicals" :key="botanical">
          {{ botanical }} <br /><br />
        </div>
      </v-col>
      <v-col cols="12" class="link nowrap pb-0">
        <div class="secTitle">Farms</div>
        <div v-for="farm in lm.farms" :key="farm.name">
          {{ farm.name }}&nbsp;({{ farm.botanical }})
        </div>
      </v-col>
      <v-col cols="12" class="pb-0">
        <div class="secTitle">Online Store</div>
        <div class="storeItem" v-for="product in lm.stores" :key="product.name">
          <v-btn
            class="pa-1"
            large
            outlined
            :href="product.url"
            target="top"
            v-html="product.name"
          ></v-btn>
          <!-- <a class="itemContent" :src="product.url"> {{ product.name }}</a> -->
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    lm: {
      type: Object,
      required: true,
      default: function () {
        return {}
      },
    },
  },
  methods: {
    bgStyle(index) {
      return require(`@/assets/img/masters/${index}.png`)
    },
  },
}
</script>
<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Teko:wght@600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;600&family=WindSong&display=swap');

.imageClass {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  opacity: 0.2;
  z-index: 1;
}
.numberClass {
  position: relative;
  font-weight: bolder;
  font-size: 8rem;
  font-family: 'Teko', sans-serif;
  line-height: 7rem;
  opacity: 0.2;
  z-index: 2;
}
.productTitle {
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: 'WindSong', cursive;
  font-size: 2rem;
  z-index: 3;
  white-space: nowrap;
}
.productSub {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0.7rem;
  z-index: 3;
  white-space: nowrap;
}
.textContainer {
  position: relative;
  opacity: 1;
  z-index: 3;
}
.secTitle {
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  margin: 10px 0px 5px;
}
.description {
  font-size: 0.8rem;
  line-height: 1.7;
}
.list {
  font-size: 0.7rem;
  line-height: 0.9;
}
.link {
  font-size: 0.8rem;
  line-height: 1.5;
}
.storeItem {
  font-size: 0.8rem;
  line-height: 1;
  margin: 0px 0px 20px;
  padding: 100px auto;
}
</style>
