<template>
  <v-dialog v-model="dialog" class="ma-1" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="white" class="mr-4" v-bind="attrs" v-on="on">
        内容を確認
      </v-btn>
    </template>
    <v-card class="grey darken-3 pt-2">
      <v-card-title class="text-h5 white--text pb-3">
        お問合せ内容
      </v-card-title>
      <v-divider dark />
      <v-card-text class="white--text text-left mt-2" style="line-hight: 1.5">
        <b>name</b>
        <p>{{ name }}</p>
        <b>e-mail</b>
        <p>{{ email }}</p>
        <b>content</b>
        <p>{{ content }}</p>
      </v-card-text>
      <v-divider dark />
      <v-card-actions class="justify-center">
        <!-- <v-spacer></v-spacer> -->
        <v-btn outlined color="white" @click.stop="dialog = false">
          Cancel
        </v-btn>
        <v-btn outlined color="white" @click.stop="send">
          SEND
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    name: {
      type: String,
      require: true,
    },
    email: {
      type: String,
      require: true,
    },
    content: {
      type: String,
      require: false,
    },
  },
  methods: {
    send() {
      this.dialog = false
      this.$emit('send', true)
    },
  },
}
</script>

<style></style>
