<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-for="sec in sections" :key="sec.title">
        <div v-html="sec.title" class="section-title nowrap"></div>
        <hr class="section-hr" />
        <div v-html="sec.contents" class="section-contents"></div>
        <div v-if="sec.links">
          <div v-for="(link, index) in sec.links" :key="index" class="mt-3">
            <v-btn
              outlined
              :href="link.url"
              :to="link.to"
              v-html="link.siteName"
            ></v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sections: [
        {
          title:
            '<span>沼津蒸留所のクラフトジン</span><span>LAZY MASTER</span>',
          contents:
            '静岡県東部と伊豆半島で取れるハーブ、スパイス、草花、果実などのボタニカルをメインに香り付けしたクラフトジンです。300Lクラスの単式蒸留器を使用し、少量ずつ丁寧に蒸留を行っています。',
        },
        {
          title: '<span>ローカルブリュワリーによる</span><span>原酒醸造</span>',
          contents:
            'LAZY MASTERの原酒には、国内を代表するクラフトビールブリュワリーの一つである、沼津駅前のブリューパブRepubrewが沼津蒸留所のためだけに醸造するクラフトジン専用の原酒を使用しています。数々のユニークなビールを製造することで培った確かな技術とノウハウで、クラフトジンの製造に適した原酒の醸造を行っています。',
          links: [{ url: 'https://www.repubrew.com/', siteName: 'Repubrew' }],
        },
        {
          title: '<span>LAZY MASTERの由来</span>',
          contents:
            'LAZY MASTERの名前の元となったのはクラフトジンのボタニカルとして使用しているみかんの産地である沼津市西浦につたわる昔ばなしです。囲碁を打っていたみかんの仙人とそれを見つけたみかんの山の持ち主のお話で、そこから怠け者の仙人="LAZY MASTER"と名づけられました。</br>ファーストリリースとなったSilky Citrusのボトルに描かれる仙人のイラストは、このみかんの仙人をイメージしたもので、沼津市出身のアーティストNino Japan（Ninomiya Yasunori)による描き下ろしです。',
          links: [
            { to: 'folklore', siteName: '仙人みかん' },
            {
              url: 'https://www.instagram.com/ninojapan/',
              siteName: 'Nino Japan Instagram',
            },
          ],
        },
      ],
    }
  },
}
</script>

<style scoped>
.section-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 4rem 0px 0px;
}
.section-contents {
  text-align: left;
  max-width: 600px;
  margin: 1rem auto;
  line-height: 1.5rem;
}
div.nowrap span {
  white-space: nowrap;
}
.section-hr {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #999;
  width: 80%;
  max-width: 350px;
  margin: auto;
}
</style>
