<template>
  <v-container>
    <v-row style="height: 80vh" align="center">
      <v-col cols="12">
        <div class="signin white--text">
          <span class="text-h3">Sign In</span>
          <v-form ref="form" @submit="signIn">
            <v-text-field
              dark
              type="text"
              placeholder="E-mail"
              v-model="email"
            />
            <v-text-field
              dark
              type="password"
              placeholder="Password"
              v-model="password"
            />
            <v-btn @click.stop="signIn">Sign In</v-btn>
          </v-form>
          <p class="mt-10 text-h5">You don't have an account?</p>
          <p>
            you must contact administrator and signup
            <!-- <router-link to="/signup">create account now!!</router-link> -->
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Signin',
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    signIn: function () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          (user) => {
            this.$router.push('/dashboard')
          },
          (err) => {
            alert(err.message)
          }
        )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.signin {
  margin-top: 20px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
input {
  margin: 10px 0;
  padding: 10px;
}
</style>
