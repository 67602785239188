<template>
  <v-container>
    <v-row
      v-for="(lm, index) in lazymaster"
      :key="index"
      class="my-10 white--text"
      :style="'background-color:' + lm.color + ';'"
    >
      <lazy-master-image :index="index" :lm="lm"></lazy-master-image>
      <lazy-master-description
        :index="index"
        :lm="lm"
      ></lazy-master-description>
    </v-row>
  </v-container>
</template>

<script>
import LazyMasterImage from '@/components/LazyMasterImage.vue'
import LazyMasterDescription from '@/components/LazyMasterDescription.vue'
export default {
  components: { LazyMasterImage, LazyMasterDescription },
  data() {
    return {
      lazymaster: [
        {
          show: true,
          serial: '001',
          color: '#d43737',
          name: 'Silky Citrus',
          jpName: 'シルキーシトラス',
          botanicals: [
            'ジュニパーベリー',
            'コリアンダー',
            'カルダモン',
            'アンゼリカ',
            '西浦みかん',
            'へだたちばな',
            'クロモジ',
            '山椒',
          ],
          description: [
            '沼津市内や伊豆半島で取れる',
            '西浦みかん、',
            'へだたちばな、',
            'クロモジ、',
            '山椒などを',
            'メインに',
            '８種類のボタニカルを使用し、',
            '柑橘が上品に香る',
            'さわやかで',
            'フルーティーな味わいに',
            '仕上がりました。',
            'ロックやストレートはもちろん、',
            '“バーの街・沼津”のジンとして',
            'カクテルベースとしても',
            'おすすめできる1本に',
            '仕上がっています。',
          ],
          point: [
            '沼津市西浦の無農薬みかんを使用',
            'LAZY MASTERの由来は昔話のなまけもの仙人',
            '沼津市の戸田（へだ）は柑橘の原酒である"たちばな"の日本最大の自生地',
          ],
          farms: [
            { name: 'Orange Park Farm', botanical: '西浦みかん', URL: '' },
            { name: 'へだたちばなの会', botanical: 'へだたちばな', URL: '' },
            { name: 'Mother Earth', botanical: 'クロモジ', URL: '' },
            { name: '久保田農園', botanical: '山椒', URL: '' },
          ],
          stores: [
            {
              name: 'LAZY MASTER ~Silky Citrus~ 42% 500ml',
              url: 'https://numazu-distillery.stores.jp/items/5fcdd0a7da019c10e6187af3',
            },
            {
              name: 'LAZY MASTER ~Silky Citrus~ 42% 200ml<br>トニックウォーターセット',
              url: 'https://numazu-distillery.stores.jp/items/60519277c9e02c3f74c2a94f',
            },
          ],
        },
        {
          show: true,
          serial: '002',
          color: '#ff8985',
          name: 'Heavenly Rose',
          jpName: 'ヘブンリーローズ',
          botanicals: [
            'ジュニパーベリー',
            'コリアンダー',
            'カルダモン',
            'リコリス',
            '薔薇',
            'ローズヒップ',
            'ジンジャー',
            'シナモン',
          ],
          description: [
            '伊豆半島の河津町でとれる',
            '薔薇をキーボタニカルとして',
            '使用しています。',
            ' 初出荷の仕込みでは',
            'ドフトボルケ、',
            'ラフランスローズ、',
            'エンジェルフェイスという',
            '3品種の薔薇の花弁を漬込み、',
            '春を感じさせる',
            '甘く華やかな',
            '香りのあとに',
            'シナモンや',
            'ジンジャーの',
            'アクセントが',
            '感じられる',
            '奥行きのある',
            '味わいに',
            '仕上がりました。',
          ],

          point: [
            '出荷前に開花してしまったバラの花弁を使用',
            '仕込み時期によって異なる品種のバラを使います',
          ],
          farms: [{ name: '伊豆ばら園', botanical: '薔薇', URL: '' }],
          stores: [
            {
              name: 'LAZY MASTER ~Heavenly Rose~ 42% 500ml',
              url: 'https://numazu-distillery.stores.jp/items/60a8a3bf1945c7184ddd1a52',
            },
            {
              name: 'LAZY MASTER ~Heavenly Rose~ 42% 200ml<br>トニックウォーターセット',
              url: 'https://numazu-distillery.stores.jp/items/60f125f90d4f3a7f4ee293b5',
            },
          ],
        },
        {
          show: true,
          serial: '003',
          color: '#37a7cc',
          name: 'Shiny Lemongrass',
          jpName: 'シャイニーレモングラス',
          botanicals: [
            'ジュニパーベリー',
            'コリアンダー',
            'カルダモン',
            'レモングラス',
            'ミント',
            'ローズマリー',
            'レモンピール',
            'ジンジャー',
            'ブラックペッパー',
          ],
          description: [
            '沼津蒸留所のクラフトジン',
            '第三弾は',
            'キーボタニカルに',
            '西伊豆の松崎町で',
            '栽培された',
            'オーガニックレモングラスを使用し',
            '自家栽培のキューバミント',
            '（イエルバブエナ）と',
            'ローズマリーなどを',
            '使用しています。',
            '国産のレモンピールも',
            '加えることで',
            'さらにシトラス感を強め、',
            '夏らしいスッキリした',
            '飲み口に仕上がっています。',
          ],
          point: [
            '西伊豆の松崎町産オーガニックレモングラスを使用',
            '沼津蒸留所で自家栽培したハーブ類を使用',
          ],
          farms: [{ name: 'とんび農園', botanical: 'レモングラス', URL: '' }],
          stores: [
            {
              name: 'LAZY MASTER ~Shiny Lemongrass~ 42% 500ml',
              url: 'https://numazu-distillery.stores.jp/items/61298598e49433245d34171a',
            },
            {
              name: 'LAZY MASTER ~Shiny Lemongrass~ 42% 200ml<br>ROSOKU MINAI ボタニカルキャンドルセット',
              url: 'https://numazu-distillery.stores.jp/items/61270e8520239733ba9fe98b',
            },
          ],
        },
        {
          show: true,
          serial: '004',
          color: '#d4aa37',
          name: 'Smoky Gold',
          jpName: 'スモーキーゴールド',
          botanicals: [
            'ジュニパーベリー',
            'コリアンダー',
            'スモークチップ',
            '金柑',
            '林檎',
            '和紅茶',
            '金木犀',
            'メープル',
            'レモン',
            'ジンジャー',
            'ブラックペッパー',
          ],
          description: [
            '市内で採れた金柑、',
            '金木犀、',
            '和紅茶を',
            'メインに',
            '11種類の',
            'ボタニカルを',
            '使用。',
            '甘さを',
            '引き出すために',
            '林檎や',
            'メープルなども',
            '入っています。',
            '更なる',
            '特徴と',
            'しまして、',
            '国内では',
            '珍しく',
            '貯蔵段階で',
            '燻製を',
            '行い',
            'その名の通り',
            'スモーキーな',
            '香りも',
            'お愉しみいただけます。',
          ],
          point: [
            '沼津市の愛鷹で取れる金柑"こんた"の摘果果実と和紅茶を使用',
            '貯蔵段階で燻煙で酒をスモーク',
          ],
          farms: [{ name: '興国園', botanical: '金柑、和紅茶', URL: '' }],
          stores: [
            {
              name: 'LAZY MASTER ~Smoky Gold~ 42% 500ml',
              url: 'https://numazu-distillery.stores.jp/items/618c70e572eb46410804017b',
            },
          ],
        },
        {
          show: true,
          serial: '005',
          color: '#e504a9',
          name: 'Nutty Strawberry',
          jpName: 'ナッティストロベリー',
          botanicals: [
            '苺（紅ほっぺ・章姫）',
            'クランベリー',
            'カカオニブ',
            'アーモンド',
            'レモンピール',
            'ローレル',
            'バニラ',
            'メープル',
            'ジュニパーベリー',
            'コリアンダー',
            'ブラックペッパー',
          ],
          description: [
            '第5作目となる〜Nutty Strawberry〜は',
            '県東部で採れた苺をメインに',
            '11種類のボタニカルを使用。',
            '苺の華やかさの中に、',
            'ナッティー感をプラスするために',
            'カカオニブやアーモンドなども',
            '香り付けに使用しています。',
            'ソーダ割りやジントニックには',
            'カットした苺を添えていただくと',
            'より一層フルーティーな',
            '風味をお楽しみいただけます。',
            'その他のお好きなジンベースのカクテルにも',
            'ご使用いただけます。',
          ],
          point: [
            '県東部でとれたイチゴを使用',
            'LAZY MASTERの由来は昔話のなまけもの仙人',
          ],
          farms: [
            {
              name: '大富農園',
              botanical: 'いちご（紅ほっぺ、章姫）',
              URL: '',
            },
          ],
          stores: [
            {
              name: 'LAZY MASTER ~Nutty Strawberry~ 42% 500ml',
              url: 'https://numazu-distillery.stores.jp/items/6257d16ac9883d7616d60895',
            },
          ],
        },
        {
          show: true,
          serial: '006',
          color: '#5E9449',
          name: 'Lushly Green',
          jpName: 'ラシュリーグリーン',
          botanicals: [
            'ジュニパー',
            'コリアンダー',
            'アンジェリカ',
            'カルダモン',
            'レモン',
            '櫻葉',
            '緑茶',
            '山椒',
            '柚子',
            'ジンジャー',
            'クロモジ',
          ],
          description: [
            '西伊豆にある松崎町は塩漬けにした',
            '桜葉の名産地です。',
            '今回のレイジーマスターは',
            '桜葉をキーボタニカルに使用し、',
            '県内産の緑茶、',
            'クロモジなど',
            '緑色のボタニカルを沢山',
            '使用しました。',
            '和食にも合いますので、',
            'ソーダで割って食中酒としても',
            'お愉しみいただけます。',
            '原酒には沼津市内の',
            'クラフトビール醸造所「Repubrew」が',
            'クラフトジンのためだけに',
            '製造したベースアルコールを',
            '使用しています。',
          ],
          point: ['伊豆半島の松崎町で取れる桜葉を使用', '静岡県産の緑茶を使用'],
          farms: [
            {
              name: '',
              botanical: '桜葉',
              URL: '',
            },
          ],
          stores: [
            {
              name: 'LAZY MASTER ~Lushly Green~ 42% 500ml',
              url: 'https://numazu-distillery.stores.jp/items/6385aa4e18324e2b0d582f31',
            },
          ],
        },
        {
          show: true,
          serial: '007',
          color: '#5d5583',
          name: 'Spiky Roots',
          jpName: 'スパイキールーツ',
          botanicals: [
            'ジュニパーベリー',
            '本わさび(静岡)',
            'ヒノキ(静岡)',
            'きゅうり(静岡)',
            'かぼす(国産)',
            '青じそ(国産)',
            'ジンジャー(国産)',
            'コリアンダー',
            'アンジェリカルート',
          ],
          description: [
            'LAZY MASTER第7作目となる',
            '～Spiky Roots～は',
            '静岡県の御殿場産わさびを',
            'キーボタニカルに',
            '使用し、',
            '静岡県産の',
            'ヒノキ、',
            'キュウリなど',
            '青く爽やかな',
            '香りのものを',
            '使用しました。',
            '和食(特に寿司や刺身)を',
            '意識して',
            '蒸留しました。',
            '擦った本わさびなどを',
            '少量入れ、',
            'ソーダ割にして',
            'いただくと',
            '食中酒として',
            '格別です。',
            '原酒には',
            '沼津市内の',
            'クラフトビール醸造所',
            '「Repubrew」が',
            'クラフトジンの',
            'ためだけに',
            '製造した',
            'ベースアルコールを',
            '使用しています。',
            'こちらの原酒は',
            'モルト(麦芽)が',
            '使用されており、',
            'リッチな',
            '風味が特徴です。',
          ],
          point: [
            '和のスパイス香る、',
            '海鮮とペアリングがオススメの',
            'わさびジン！',
          ],
          farms: [
            {
              name: '田代 耕一氏',
              botanical: '真妻わさび',
              URL: '',
            },
          ],
          stores: [
            {
              name: 'LAZY MASTER ~Spiky Roots~ 42% 500ml',
              url: 'https://numazu-distillery.stores.jp/items/657797ce5b84b404c142a27f',
            },
          ],
        },
      ],
    }
  },
}
</script>
