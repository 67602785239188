<template>
  <default-page>
    <section-frame
      title="Company"
      subTitle="会社概要"
      :bBack="true"
      class="comp"
    >
      <div width="100%" class="d-flex justify-center">
        <table max-width="600px">
          <tr>
            <td class="td_title"><span>商号</span></td>
            <td class="content">株式会社FLAVOUR（FLAVOUR Ltd.）</td>
          </tr>
          <tr>
            <td class="td_title"><span>設立</span></td>
            <td class="content">2019年 7月</td>
          </tr>
          <tr>
            <td class="td_title"><span>本社所在地</span></td>
            <td class="content">静岡県沼津市上土町8</td>
          </tr>
          <tr>
            <td class="td_title"><span>TEL</span></td>
            <td class="content">(055) 955 - 6222</td>
          </tr>
          <tr>
            <td class="td_title"><span>事業内容</span></td>
            <td class="content">
              酒類（スピリッツ）の製造販売<br />飲食業<br />酒造業開業支援<br />デザイン<br />イベント企画<br />
            </td>
          </tr>
        </table>
      </div>
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
export default {
  components: { DefaultPage, SectionFrame },
}
</script>

<style scoped>
.comp .td_title {
  font-size: 0.7rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px 20px;
}
.comp .td_title span {
  display: inline-block;
}
.comp .content {
  font-size: 0.9rem;
  font-weight: 200;
  padding: 5px 30px;
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
