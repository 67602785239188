<template>
  <v-container>
    <v-row v-if="isLoading" class="justify-center">
      <loading>店舗情報を読み込んでいます</loading>
    </v-row>
    <v-row v-else class="wrapper">
      <v-col class="label" cols="12">お店の種類 / Shop Type</v-col>
      <v-col cols="12" class="mb-10">
        <v-btn
          class="ma-2"
          dark
          :outlined="shopTypes.length === shopTypes_selected.length"
          @click="btnTypeAll"
          >全て</v-btn
        >
        <v-btn
          v-for="shopType in shopTypes"
          :key="shopType"
          class="ma-2"
          dark
          :outlined="shopTypes_selected.includes(shopType)"
          @click="btnTypeClick(shopType)"
        >
          <v-icon left v-html="getIcon(shopType)"></v-icon>{{ shopType }}</v-btn
        >
      </v-col>
      <v-col class="label" cols="12">都道府県 / Prefecture</v-col>
      <v-col cols="12" class="mb-10">
        <v-btn
          class="ma-2"
          dark
          :outlined="prefs.length === prefs_selected.length"
          @click="btnPrefAll"
          >全て</v-btn
        >
        <v-btn
          v-for="pref in prefs"
          :key="pref"
          class="ma-2"
          dark
          :outlined="prefs_selected.includes(pref)"
          @click="btnPrefClick(pref)"
          >{{ pref }}</v-btn
        >
      </v-col>
      <v-col
        v-for="(shop, index) in filteredList"
        :key="index"
        cols="12"
        class="justify-center"
        ><div class="d-flex justify-center">
          <div class="name">{{ shop.name }}</div>
          <div v-if="shop.typeBit & 1" class="type-icon">
            <v-icon dark small> mdi-bottle-tonic-outline </v-icon>
          </div>
          <div v-if="shop.typeBit & 2" class="type-icon">
            <v-icon dark small> mdi-glass-tulip </v-icon>
          </div>
          <div v-if="shop.typeBit & 4" class="type-icon">
            <v-icon dark small> mdi-bed-outline </v-icon>
          </div>
          <div v-if="shop.typeBit & 8" class="type-icon">
            <v-icon dark small> mdi-dots-horizontal-circle-outline </v-icon>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="address">
            {{ shop.pref }}{{ shop.city }}{{ shop.address }}
          </div>
          <div v-if="shop.iframe !== ''">
            <v-btn
              @click="clickDialog(shop)"
              class="ml-2 mt-0"
              dark
              x-small
              outlined
              >map</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="mt-16 pt-16 text-caption"
        >郵便番号順に掲載<br />詳しいお取り扱い内容や在庫につきましては各店舗にお問い合わせください</v-col>
        <v-col cols="12" class="mt-2 mb-16 text-caption"
        >in order of postal code <br />please contact each store for details</v-col
      >
      <v-dialog v-model="dialog" max-width="600px">
        <v-card v-html="iframe"> </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import Loading from './Loading.vue'

export default {
  components: { Loading },
  data() {
    return {
      shopTypes: ['酒販店', '飲食店', '宿泊', 'その他'],
      shopTypes_selected: ['酒販店', '飲食店', '宿泊', 'その他'],
      prefs: [],
      prefs_selected: ['静岡県'],
      shopList: [],
      dialog: false,
      iframe: '',
      isLoading: true,
    }
  },
  created() {
    axios
      //Google SpreadsheetからJsonを取得
      .get(
        'https://script.google.com/macros/s/AKfycbweUTl0A_RuCBHj3vO97DNDrCwkNmy_MNEcVK6N18WmxEfmcqH9zD0PJ3EI4tRxbc201g/exec'
      )
      .then((res) => {
        res.data.forEach((item) => {
          //顧客リストに追加
          this.shopList.push(item)
          //都道府県がリストになければ追加
          if (!this.prefs.includes(item.pref)) {
            this.prefs.push(item.pref)
            // this.prefs_selected.push(item.pref)
          }
        })
        this.isLoading = false
      })
      .catch((err) => console.error(err))
  },
  computed: {
    filteredList() {
      // let array = this.shopList.filter((shop) => {
      //   return (
      //     this.shopTypes_selected.includes(shop.type) &&
      //     this.prefs_selected.includes(shop.pref)
      //   )
      // })
      let array = this.shopList.filter((shop) => {
        return (
          (this.typeSelectedBit & shop.typeBit) > 0 &&
          this.prefs_selected.includes(shop.pref)
        )
      })
      return array
    },
    typeSelectedBit() {
      let bit = 0
      if (this.shopTypes_selected.includes('酒販店')) bit += 1
      if (this.shopTypes_selected.includes('飲食店')) bit += 2
      if (this.shopTypes_selected.includes('宿泊')) bit += 4
      if (this.shopTypes_selected.includes('その他')) bit += 8
      return bit
    },
  },
  methods: {
    btnTypeClick(item) {
      const index = this.shopTypes_selected.indexOf(item)
      if (index === -1) {
        this.shopTypes_selected.push(item)
      }
      // else if(this.shopTypes.length === this.shopTypes_selected.length){
      //   this.shopTypes_selected.splice(0, this.shopTypes_selected.length)
      //   this.shopTypes_selected.push(item)
      // }
      else {
        this.shopTypes_selected.splice(index, 1)
      }
    },
    btnTypeAll() {
      if (this.shopTypes.length === this.shopTypes_selected.length) {
        this.shopTypes_selected.splice(0, this.shopTypes_selected.length)
      } else {
        this.shopTypes_selected.splice(0, this.shopTypes_selected.length)
        this.shopTypes_selected = [...this.shopTypes]
      }
    },
    btnPrefClick(item) {
      const index = this.prefs_selected.indexOf(item)
      if (index === -1) {
        this.prefs_selected.push(item)
      } else {
        this.prefs_selected.splice(index, 1)
      }
    },
    btnPrefAll() {
      if (this.prefs.length === this.prefs_selected.length) {
        this.prefs_selected.splice(0, this.prefs_selected.length)
      } else {
        this.prefs_selected.splice(0, this.prefs_selected.length)
        this.prefs_selected = [...this.prefs]
      }
    },
    getIcon(type) {
      switch (type) {
        case '酒販店':
          return 'mdi-bottle-tonic-outline'
        case '飲食店':
          return 'mdi-glass-tulip'
        case '宿泊':
          return 'mdi-bed-outline'
        default:
          return 'mdi-dots-horizontal-circle-outline'
      }
    },
    clickDialog(shop) {
      this.iframe = shop.iframe
      this.dialog = !this.dialog
    },
  },
}
</script>

<style scoped>
.wrapper {
  background-color: rgba(10, 10, 10, 0.6);
}
.label {
  font-size: 0.9rem;
  font-weight: bold;
}
.name {
  font-size: 1.2rem;
  font-weight: 800;
  margin: 0 0 0.3rem;
}
.type-icon {
  width: 27px;
  height: 27px;
  border: 0px solid white;
  border-radius: 20%;
  background-color: #444;
  margin: 1px 0 0 0.5rem;
}
.address {
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
.map {
  margin-top: 0.5rem;
  font-size: small;
  font-weight: 800;
}
</style>
