<template>
  <v-container fluid
    ><v-row>
      <v-col cols="12" class="imgContainer white--text pa-0" :style="setStyle">
        <section-title
          :title="title"
          :subTitle="subTitle"
          :fontSize="fontSize"
          class="title"
        ></section-title>
        <div height="100%"><slot></slot></div> </v-col></v-row
  ></v-container>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue'
export default {
  components: {
    SectionTitle,
  },
  props: {
    imgURL: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      divStyle:
        'center  bottom 100px no-repeat; height:50vh; width:100vw; background-size: cover; background-attachment: fixed; ',
    }
  },
  computed: {
    setStyle() {
      return 'background:url(' + this.imgURL + ') ' + this.divStyle
    },
  },
}
</script>

<style scoped>
.imgContainer {
  position: relative;
}
.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
