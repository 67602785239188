<template>
  <default-page>
    <section-frame title="Contact" subTitle="お問合せ">
      <v-row class="justify-center">
        <v-col cols="12" sm="8">
          <span id="contact_caption" class="grey-70 small"
            >沼津蒸留所コンタクトフォームからお問い合わせ頂きありがとうございます。<br />
            担当者より早急にご返信致しますので今しばらくお待ちください。</span
          ><br /><br />
          <v-btn
            outlined
            large
            color="white"
            to="/"
            target="_self"
            class="py-1"
            >TOPに戻る
          </v-btn>
        </v-col>
      </v-row>
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'

export default {
  components: { DefaultPage, SectionFrame },

  data: () => {
    return {}
  },
}
</script>
