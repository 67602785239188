<template>
  <default-page>
    <!-- <page-image
      title="Contact"
      subTitle="お問合せ"
      fontSize="6rem"
    ></page-image> -->
    <section-frame title="Contact" subTitle="お問合せ">
      <v-row class="justify-center">
        <v-col cols="12" sm="8">
          <span id="contact_caption" class="grey-70 small"
            >Please enter your comment and press submit button.<br />
            We will reply from info@flavour.jp soon.</span
          ><br /><br />
          <form method="post" action="mail/mail.php" ref="form">
            <v-text-field
              solo
              type="text"
              name="name"
              v-model="name"
              :error-messages="nameErrors"
              :counter="10"
              label="enter your name"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
            <v-text-field
              solo
              type="email"
              name="e-mail"
              v-model="email"
              :error-messages="emailErrors"
              label="enter your e-mail address"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>
            <v-textarea
              solo
              v-model="content"
              name="content"
              label="Comment"
              rows="12"
              @input="$v.content.$touch()"
              @blur="$v.content.$touch()"
            ></v-textarea>
            <!-- <v-btn
              type="submit"
              class="mr-4"
              :disabled="submitStatus === 'PENDING'"
            >
              Submit
            </v-btn> -->
          </form>
        </v-col>
      </v-row>
      <confirm-dialog
        :name="name"
        :email="email"
        :content="content"
        @send="submit"
      ></confirm-dialog>
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
// import PageImage from '@/components/PageImage.vue'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'

// import mailer from '@/mail.js'
export default {
  components: { DefaultPage, SectionFrame, ConfirmDialog },
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(10) },
    email: { required, email },
    content: {},
  },
  data: () => {
    return {
      name: '',
      email: '',
      content: '',
      submitStatus: '',
      showConfirm: false,
    }
  },

  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength &&
        errors.push('Name must be at most 10 characters long')
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    contentErrors() {
      const errors = []
      if (!this.$v.content.$dirty) return errors
      return errors
    },
  },

  methods: {
    clear() {
      this.$v.$reset()
      this.name = ''
      this.email = ''
      this.content = ''
    },
    confirm() {
      this.submitStatus = 'PENDING'
      this.showConfirm = true
    },
    submit() {
      this.submitStatus = ''
      this.$refs.form.submit()
    },
  },
}
</script>
