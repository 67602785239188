<template>
  <v-menu ref="filterDialog" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon x-small v-bind="attrs" v-on="on">
        <v-icon v-if="list.length === array.length" small
          >mdi-filter-menu-outline</v-icon
        >
        <v-icon v-else small>mdi-filter-menu</v-icon>
        <span class="caption" style="color:white;">{{ filterName }}</span>
      </v-btn>
    </template>
    <v-card class="pa-3">
      <v-checkbox
        value
        :indeterminate="array.length > 0 && array.length !== list.length"
        :input-value="array.length === list.length"
        hide-details
        label="すべて選択"
        @click="checkAll"
      ></v-checkbox>
      <v-divider></v-divider>
      <v-checkbox
        v-for="(s, index) in list"
        :key="index"
        v-model="array"
        :label="s"
        :value="s"
        hide-details
        class="ma-0"
      ></v-checkbox>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
      default: () => [],
    },
    filterName: {
      type: String,
      require: false,
      default: '',
    },
  },
  computed: {
    array: {
      get: function () {
        return this.selected
      },
      set: function (v) {
        return this.$emit('setSelected', v)
      },
    },
  },
  methods: {
    checkAll() {
      if (this.array && this.array.length === this.list.length) {
        this.array = []
      } else {
        this.$set(this, 'array', this.list)
      }
    },
  },
}
</script>
