<template>
  <v-sheet
    color="rgba(255,255,255,0.85)"
    class="onImg black--text mb-3 mx-3 pb-10 elevation-5"
  >
    <slot name="header"></slot>
    <h2 class="py-8">{{ menuTitle }}</h2>
    <v-row
      v-for="item in menuList"
      :key="item[0]"
      class="justify-center py-0 mb-1"
    >
      <v-col
        cols="4"
        sm="2"
        class="text-right py-0"
        style="font-weight: bold"
        >{{ item[0] }}</v-col
      >
      <v-col cols="4" sm="2" class="text-left py-0" style="font-size: 0.8rem">{{
        item[1]
      }}</v-col>
      <v-col cols="1" sm="1" class="text-left py-0" style="font-weight: bold">{{
        item[2] | currency
      }}</v-col>
    </v-row>
    <slot name="footer"></slot>
  </v-sheet>
</template>

<script>
import TheChamberMenu from '@/components/TheChamberMenu.vue'
export default {
  components: {
    TheChamberMenu,
  },
  // data() {
  //   return {
  //     menuList: [['coffee', 'コーヒー', '	&yen;300']],
  //   }
  // },
  props: {
    menuList: {
      type: Array,
      required: true,
      default: function () {
        return [['nodata', 'nodata2', 'nodata3']]
      },
    },
    menuTitle: {
      type: String,
      required: false,
      default: 'Menu',
    },
  },
  filters: {
    currency: function (value) {
      return '¥' + value.toLocaleString()
    },
  },
}
</script>
