<template>
  <section>
    <!-- <the-chamber-menu :menuList="menuList" menuTitle="Drink Menu">
      <template v-slot:footer>and more...</template>
    </the-chamber-menu> -->
    <!-- <the-chamber-menu :menuList="foodList" menuTitle="Food Menu">
    </the-chamber-menu> -->
    <v-sheet
      height="auto"
      width="auto"
      class="black--text mb-3 mx-3 py-10 elevation-5"
    >
      <v-row>
        <v-col cols="12" sm="4" align="center">
          <a href="https://flavour.jp/img/theChamber/24-06_bar-menu1.jpg">
            <v-img
              class="thumbnails"
              :src="require('/public/img/theChamber/24-06_bar-menu1.jpg')"
              width="auto"
            ></v-img>
          </a>
        </v-col>
        <v-col cols="12" sm="4" align="center">
          <a href="https://flavour.jp/img/theChamber/24-06_bar-menu2.jpg">
            <v-img
              class="thumbnails"
              :src="require('/public/img/theChamber/24-06_bar-menu2.jpg')"
              width="auto"
            ></v-img
          ></a>
        </v-col>
        <v-col cols="12" sm="4" align="center">
          <a href="https://flavour.jp/img/theChamber/24-02_bar-menu3.jpg">
            <v-img
              class="thumbnails"
              :src="require('/public/img/theChamber/24-02_bar-menu3.jpg')"
              width="auto"
            ></v-img>
          </a>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0"
          ><span class="caption"
            ><v-icon small color="grey darken-2"
              >mdi-magnify-plus-outline</v-icon
            >画像をクリックで拡大</span
          ></v-col
        >
      </v-row>
    </v-sheet>
    <v-sheet
      style="float: clear"
      height="auto"
      width="auto"
      class="mx-3 mb-3 white--text"
      color="rgba(255,255,255,0.1)"
    >
      <div class="text-left" style="padding: 20px">
        <ul>
          <li class="pb-2">
            お会計はキャッシュオン（前払い制）でお願いいたします
          </li>
          <li class="pb-2">
            ドリンクはリユースカップでご提供しています。<br />ご注文の際にリユースカップ代300円を頂戴します。リユースカップは川沿いなどに持ち出してお楽しみいただけます。そのままお持ち帰りもOKです！
            <br />レジにてご返却していただく場合は300円を返金いたします。（お支払い方法に関わらず現金での返金となります）
          </li>
          <!-- <li>【お知らせ】ご好評につき現在リユースカップの在庫が大変少なくなっております。テイクアウト用のカップでご提供させていただく場合がございますのでご了承ください。（その場合カップ代３００円は不要です）</li> -->
        </ul>
      </div>
    </v-sheet>
  </section>
</template>

<script>
import TheChamberMenu from '@/components/TheChamberMenu.vue'
export default {
  components: {
    TheChamberMenu,
  },
  // data() {
  // return {
  //   menuList: [
  //     ['Gin Tonic', 'ジントニック', '700'],
  //     ['Gin Ricky', 'ジンリッキー', '700'],
  //     ['Gin back', 'ジンバック', '700'],
  //     ['Sea Scout', 'シースカウト', '700'],
  //     ['Hot Gin', 'ホットジン', '700'],
  //     ['Martini', 'マティーニ', '900'],
  //     ['Gimlet', 'ギムレット', '900'],
  //     ['Negroni', 'ネグローニ', '900'],
  //     ['Soft Drinks', 'ソフトドリンク', '500'],
  //   ],
  //   foodList: [
  //     ['Baguette', 'バゲット', '300'],
  //     ['Roast Beef', 'ローストビーフ', '600'],
  //     ['Ox Tongue Pie', '牛タンのパイ', '600'],
  //     ['Shrimp Croquette', '桜海老のコロッケ', '600'],
  //     ['Gizzard Confit', '砂肝のコンフィ', '600'],
  //     ['Steamed Fish', '白身魚のワイン蒸し', '800'],
  //     ['Shrimp Ajillo', '海老のアヒージョ', '1000'],
  //     ['Bouillabaisse', '赤魚のブイヤベース', '1100'],
  //   ],
  // }
  // },
}
</script>
