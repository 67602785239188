<template>
  <div id="instagram">
    <v-container>
      <v-row v-if="loading"
        ><v-col><loading /></v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col
          cols="6"
          sm="4"
          md="2"
          v-for="r in res6"
          :key="r.id"
          class="div-link text-left"
        >
          <span class="top-user-name">{{ r.like_count }}{{ r.username }}</span
          ><br />
          <v-img :src="r.src_url" aspect-ratio="1"></v-img>
          <span class="time-stamp">{{ fromNow(r.timestamp) }}</span
          ><br />
          <span class="user-name">{{ r.username }} </span>
          <span class="caption">{{ IGTruncate(r.caption) }}</span
          ><span class="readMore">...続きを読む</span><br />
          <router-link
            :to="'/newsTheChamber/' + r.id"
            class="link"
            target="self"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/ja'
import Loading from '@/components/Loading.vue'

// const Promise = require('es6-promise').Promise
export default {
  components: { Loading },
  data() {
    return {
      res: [],
      loading: true,
    }
  },
  computed: {
    res6() {
      return this.res.slice(0, 6)
    },
  },
  created: function () {
    axios
      .get('https://flavour.jp/insta/theChamberFeeds.php')
      .then((response) => {
        console.log('res:', response)
        response.data.media.data.forEach((d, index) => {
          console.log(index, d.id)
          d.src_url = 'thumbnail_url' in d ? d.thumbnail_url : d.media_url
          this.res.push(d)
          this.loading = false
        })
        // console.log(response.data)
        // console.log(response.status) // 200
      })
  },
  methods: {
    formatDate(d) {
      return moment(d).format('YYYY-MM-DD')
    },
    fromNow(m) {
      return moment(m).fromNow()
    },
    IGTruncate(str) {
      if (str) {
        // console.log('str', str, str.length ?? 'no')
        var length = 15
        // var ommision = '...続きを読む'
        if (str.length <= length) {
          return str
        }
        return str.substring(0, length) //+ ommision
      } else {
        return ''
      }
    },
  },
}
</script>
<style scoped>
.top-user-name {
  color: #999999;
  font-size: 0.7rem;
  line-height: 0.5rem;
}
.time-stamp {
  color: #999999;
  font-size: 0.7rem;
  line-height: 0.5rem;
}
.user-name {
  font-weight: bold;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 0.68rem;
}
.caption {
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 0.68rem;
}
.readMore {
  font-weight: bold;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 0.68rem;
}
.div-link {
  position: relative;
}

.div-link .link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
